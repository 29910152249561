@font-face {
    font-family: 'robotoslab';
    src: url('fonts/robotoslab-regular.eot');
    src: url('fonts/robotoslab-regular.eot?#iefix') format('embedded-opentype'),
         url('fonts/robotoslab-regular.woff') format('woff'),
         url('fonts/robotoslab-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'robotoslab';
    src: url('fonts/robotoslab-bold.eot');
    src: url('fonts/robotoslab-bold.eot?#iefix') format('embedded-opentype'),
         url('fonts/robotoslab-bold.woff') format('woff'),
         url('fonts/robotoslab-bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'robotoslab';
    src: url('fonts/robotoslab-light.eot');
    src: url('fonts/robotoslab-light.eot?#iefix') format('embedded-opentype'),
         url('fonts/robotoslab-light.woff') format('woff'),
         url('fonts/robotoslab-light.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}



html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    list-style: none;
}


.container_main.App .marquiz__button_fixed {
    height: 36px;
    line-height: 36px;
    font-family: 'robotoslab'!important;
    font-weight: 100;
    font-size: 16px;
    text-transform: uppercase;
    border-radius: 0px;
}
.container_main.App .marquiz__button_fixed .icon-quiz {
    height: 22px;
    width: 22px;
    padding: 4px 2px 4px 2px;
}
.container_main.App{    
    font-family: 'robotoslab';
    text-align: left;
    min-width: 320px;
    overflow: hidden;
    color: #384251;
}
#root{
    position: relative;
    z-index: 1;
}
.clr{
    clear: both;
}
.Mui-error ~ .MuiInput-underline:before {
    border-bottom: 2px solid rgb(255, 0, 0)!important;
    transition: .3s;
}
.Mui-error{
    color: rgba(255, 0, 0)!important;
    font-weight: bold!important;
    transition: .3s;
}


/* menu */
.menu{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: rgb(255 255 255 / 62%);
}
.cw_main{
    display: block;
    margin: 0 auto;
    max-width: 1372px;
    box-sizing: border-box;
}
.menu .cw_main {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
    max-width: 1318px;
}

logo img{
    display: block;
    width: 100%;
}
logo {
    max-width: 100px;
}
.menu_btn{
    display: none;
}
.top_menu{
    display: block;
}
.top_menu a span{
    display: block;
    position: relative;
    font-size: 14px;
    line-height: 1;
    text-transform: uppercase;

}
.top_menu a {
    display: inline-block;
    position: relative;
    vertical-align: top;
    text-decoration: none;
    color: #384251;
    transition: .4s;
}
.top_menu a:focus{
    color: #58c6e8;
}
.top_menu a+a{
    margin-left: 52px;
}

.top_menu a:before{
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    background: #89a1bb;
    left: 0;
    opacity: 1;
    bottom: -4px;
    transition: .4s;
    pointer-events: none;
}
.top_menu a:hover:before{
    opacity: 0;
    bottom: -6px;
}
.top_menu a:focus:before{
    opacity: 0;
    bottom: -6px;
}


.top_menu a:after{
    display: block;
    position: absolute;
    content: '';
    left: 0px;
    bottom: -3px;
    height: 8px;
    width: 0%;
    opacity: 0;
    background: #58c6e8;
    transition: 0.4s;
}
.top_menu a:hover:after{
    width: 45%;
    opacity: 1;
    left: 0;
}
.top_menu a span{
    position: relative;
    z-index: 2  ;
}
.top_menu a:focus:after{
    opacity: 0;
}

.menu_right{
    display: block;
}
.call_phone{
    display: block;
    position: relative;
    color: #fff;
    font-size: 20px;
    line-height: 1;
    padding: 12px 14px 12px 50px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    background: #58c6e8 url(../images/phone_top_bg.png)no-repeat center left 11px;
    font-weight: normal;
    transition: 0.4s;
    text-decoration: none;
}
.call_phone:hover{
    background: #459fbb url(../images/phone_top_bg.png)no-repeat center left 11px;
}
/* menu */

/* header */
.header{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    background: url(../images/header_bg.jpg)no-repeat center top;
    background-size: cover;
    height: 100vh;
    box-sizing: border-box;
    min-height: 600px;
}
.hd_inner{
    display: block;
    position: relative;
    text-align: center;
}
.hd_inner *{
    position: relative;
    z-index: 2;
}
.hd_inner:before {
    display: block;
    position: absolute;
    content: '';
    width: 1px;
    height: 1px;
    left: 50%;
    top: 50%;
    box-shadow: 0px 0px 49px 160px rgb(255 255 255 / 80%);
    border-radius: 50%;
    transform: translate(-50%,-50%) scaleX(3);
}
.hd_loc{
    display: inline-block;
    padding-left: 34px;
    line-height: 32px;
    color: #58c6e8;
    font-size: 18px;
    text-transform: uppercase;
    background:url(../images/hd_icon.svg)no-repeat center left;
}
h1{
    font-size: 85px;
    text-transform: uppercase;
    color: #20446c;
    line-height: 1;
    font-weight: normal;
    margin-top: 12px;
}
.hd_info{
    display: inline-block;
    margin-top: 18px;
    font-size: 22px;
    line-height: 1;
    color: #3b4251;
    background: #ffc140;
    padding: 20px 30px 20px 30px;
}
.hd_info span {
    display: block;
    font-size: 150%;
    margin-top: 12px;
    line-height: 100%;
}
/* header */


/* comfort */
.comfort{
    padding-top: 70px;
    padding-bottom: 40px;
}
.tm{
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    font-weight: normal;
}
.tm:before{
    display: block;
    position: absolute;
    content: '';
    width: 57px;
    height: 3px;
    top: 50%;
    left: -82px;
    background:#58c6e8;
}
.tm sup{
    display: inline-block;
    font-size: 24px;
    line-height: 1;
    color: #c3c7ce;
}
.tm span{
    display: inline-block;
    font-size: 36px;
    line-height: 1;
    color: #3b4251;
    text-transform: uppercase;
    margin: 10px 0px ;
}

.comf_list {
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 100px;
}
.cmf_el{
    display: block;
    width: 400px;
}
.cmf_img {
    display: inline-block;
}
.cmf_img + .cmf_img{
    margin-top: 54px;
}
.cmf_el:nth-child(2) .cmf_img + .cmf_img{
    margin-top: 43px;
}
.cmf_el:nth-child(3) {
    text-align: right;
}
.cmf_img img{
    display: block;
    max-width: 100%;
}
.cmf_img span {
    display: flex;
    min-height: 45px;
    font-size: 20px;
    color: #1f2229;
    line-height: 1;
    margin-top: 24px;
    padding-left: 18px;
    border-left: 3px solid #58c6e8;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
}
/* comfort */


/* loc */
.loc{
    padding-top: 100px;
    padding-bottom: 50px;
}
.loc_list{
    position: relative;
    margin-top: 102px;
    padding-left: 74px;
}
.loc_list:after{
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    left: 0;
    top: 50%;
    background: url(../images/loc_line.png)no-repeat center top;
    background-size: 100%;
    transform: translateY(-50%);
}
.loc_el{
    display: inline-block;
    vertical-align: top;
    position: relative;
}
.loc_el:before{
    display: block;
    position: absolute;
    content: '';
    width: 7px;
    height: 36px;
    background: url(../images/loc_dots.png)no-repeat center top;
    background-size: 100%;
    left: 50%;
    transform: translateX(-50%);
}
.loc_el:nth-child(odd)::before{
    top: -48px;
}
.loc_el:nth-child(even)::before{
    transform-origin: center center;
    transform: translateX(-50%) rotate(180deg);
    top: auto;
    bottom: -49px;
}
.loc_el:nth-child(odd){
    margin-top: 166px;
}
.loc_el + .loc_el{
    margin-left: 150px;
}
.loc_info{
    display: block;
    position: absolute;
    top: 50%;
    left: 90px;
    transform: translateY(-50%);
}
.loc_info *{
    white-space: nowrap;
}
.loc_info span{
    position: relative;
    font-size: 20px;
    line-height: 1;
    margin-top: 7px;
}
.loc_info span:before{
    display: block;
    position: absolute;
    content: '';
    width: 54px;
    height: 5px;
    background: #58c6e8;
    left: 0;
    bottom: 0px;
}
.loc_info span l{
    position: relative;
    z-index: 2;
}
.loc_info b{
    display: block;
    font-size: 16px;
    line-height: 1;
    margin-top: 7px;
}
/* loc */


/* presentation */
.presentation {
    padding-top: 184px;
    padding-bottom: 50px;
    position: relative;
}
.presentation .tm sup{
    color: #58c6e8;
}
.prst_dec {
    display: block;
    position: absolute;
    height: 100%;
    width: calc(50vw - 686px + 1372px);
    background: url(../images/prst_dec.jpg)no-repeat center bottom;
    /* background-size: 100%; */
    top: 0;
    left: auto;
    right: 0;
}
.presentation .cw_main{
    position: relative;
    padding-left: 158px;
    z-index: 2;
}
.presentation .cw_main .btn_style{
    display: inline-block;
    font-size: 18px;
    margin: 0 auto;
    margin-top: 66px;
    margin-left: 100px;
    padding: 0px 40px;
    transform: translateY(50%);
}
/* presentation */

/* near_house */
.near_house{
    padding-top: 50px;
    padding-bottom: 50px;
}
.nh_slid_wrap {
    display: flex;
    position: relative;
    width: 100%;
    padding-left: calc(50vw - 686px);
    box-sizing: border-box;
    margin-top: 58px;
    align-content: flex-start;
    justify-content: space-between;
    align-items: flex-start;
}
.nh_nav {
    display: flex;
    max-width: 300px;
    padding-top: 146px;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
}
.nh_nav li{
    display: inline-block;
    position: relative;
    padding: 0 3px;
    transition: 0.4s;
}
.nh_nav li:after{
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 5px;
    background: #58c6e8;
    left: 0;
    bottom: 1px;
    opacity: 0;
    transition: 0.4s;
}
.nh_nav li+li{
    margin-top: 26px;
}
.nh_nav li span{
    position: relative;
    z-index: 2;
    font-size: 18px;
    line-height: 1;
    text-transform: uppercase;
    color: #3b4251;
    cursor: pointer;
}

.nh_nav li:hover:after{
    opacity: 1;
}

.nh_nav li.act:after{
    opacity: 1;
}
.nh_slid{
    display: block;
    width: calc(100% - 410px);
}
.nh_in {
    display: flex!important;
    height: 584px;
    padding-right: 40px;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}
.nh_in img{
    max-height: 100%;

}
.slid_nav{
    display: inline-block;
    position: relative;
}
.near_house .slid_nav span{
    display: block;
    margin-bottom: 30px;
}
.near_house .slid_nav{
    display: block;
    position: absolute;
    width: 430px;
    background: #fff;
    box-sizing: border-box;
    padding: 42px 50px 10px 64px;
    right: 244px;
    right: 13vw;
    bottom: 0;
    font-size: 16px;
    line-height: 1.1;
    color: #3b4454;
}


.slid_nav .slid_nav_el{
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 42px;
    height: 13px;
    cursor: pointer;
    transition: 0.4s;
}
.slid_nav_l{
    background: url(../images/slid_prev_ar.svg)no-repeat center top;
    background-size: 100%;
}
.slid_nav_r{
    background: url(../images/slid_next_ar.svg)no-repeat center top;
    background-size: 100%;
}
.slid_nav_r{
    margin-left: 15px;
}
.slid_nav_l:hover{
    background: url(../images/slid_prev_ar_act.svg)no-repeat center top;
    background-size: 100%;
}
.slid_nav_r:hover{
    background: url(../images/slid_next_ar_act.svg)no-repeat center top;
    background-size: 100%;
}
/* near_house */



/* plan_price */
.plan_price{
    padding-bottom: 90px;
    padding-top: 70px;
}
.plpr_nav{
    display: block;
    position: relative;
    box-sizing: border-box;
    margin-top: 56px;
}
.plpr_nav p{
    color: #bbbdc2;
    font-size: 18px;
    margin-bottom: 12px;
}
.plpr_nav a{
    display: inline-block;
    vertical-align: top;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    transition: 0.4s;
}
.plpr_nav a:after {
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 5px;
    background: #58c6e8;
    left: 0;
    bottom: 1px;
    opacity: 0;
    transition: 0.4s;
}
.plpr_nav a + a{
    margin-left: 24px;
}
.plpr_nav a span{
    display: block;
    position: relative;
    font-size: 20px;
    line-height: 1;
    z-index: 2;
    color: #3b4251;
    transition: 0.4s;
}
.plpr_nav a:hover span{
    color: #5ac4ee;
}
.plpr_nav a.act:after{
    opacity: 1;
}
.plpr_nav a.act:hover span{
    color: #3b4251;
}

.plpr_list{
    display: flex;
    align-items: flex-start;
    margin-top: 36px;
    flex-wrap: wrap;
    margin-bottom: -50px;
}
.plpr_in{
    display: inline-block;
    vertical-align: top;
    width: 328px;
    box-sizing: border-box;
    border: 1px solid #f5f5f6;
    padding: 44px 40px 0px 40px;
    cursor: pointer;
    margin-bottom: 50px;
    box-sizing: border-box;
    width: 25%;
}
.plpr_name{
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: 17px;
    line-height: 1;
    color: #69c8ef;
    text-transform: uppercase;
    text-decoration: underline;
}
.total_area{
    display: block;
    text-align: center;
    font-size: 12px;
    line-height: 1;
    text-transform: uppercase;
    color: #7b8393;
    margin-top: 8px;
}
.total_area b{
    color: #3b4251;
    font-size: 18px;
    line-height: 1;
    text-transform: none;
}
.total_area b sup{
    font-size: 8px;
    line-height: 0;
}
.plpr_img {
    display: flex;
    align-items: center;
    margin: 0 auto;
    margin-top: 30px;
    height: 274px;
    width: 100%;
    margin-bottom: 14px;
    align-content: center;
    justify-content: center;
}
.plpr_img img{
    display: block;
    max-width: 100%;
    max-height: 100%;
}
.plpr_btn{
    display: block;
    position: relative;
    width: 224px;
    box-sizing: border-box;
    border: 1px solid #ffc140;
    background: #fff;
    font-size: 16px;
    line-height: 46px;
    text-align: center;
    color: #3b4251;
    margin: 0 auto;
    transform: translateY(50%);
    transition: .4s;
}
.plpr_in:hover .plpr_btn{
    background: #ffc140;
}
/* plan_price */


/* galery */
.galery{
    padding-top: 90px;
    padding-bottom: 80px;
}
.galery .cw_main{
    position: relative;
}
.gl_slid{
    display: block;
    width: 100%;
    margin-top: 54px;
}
.gl_in{
    display: block;
    padding: 0;
}
.galery .slid_nav {
    display: flex;
    position: absolute;
    background: #fff;
    width: 400px;
    padding: 40px 10px 32px 56px;
    box-sizing: border-box;
    align-content: center;
    justify-content: space-between;
    align-items: flex-end;
    bottom: 0;
    right: 0;
}
.galery .slid_nav .slid_nav_el {
    height: 20px;
}
.galery .slid_nav_l{
    background: url(../images/slid_prev_ar.svg)no-repeat center left;
    background-size: auto 100%;
}
.galery .slid_nav_r{
    background: url(../images/slid_next_ar.svg)no-repeat center right;
    background-size: auto 100%;
}
.galery .slid_nav_r{
    margin-left: 15px;
}
.galery .slid_nav_l:hover{
    background: url(../images/slid_prev_ar_act.svg)no-repeat center left;
    background-size: auto 100%;
}
.slid_nav_r:hover{
    background: url(../images/slid_next_ar_act.svg)no-repeat center right;
    background-size: auto 100%;
}
.slid_counter b{
    font-weight: 100;
    color: #3b4251;
    font-size: 36px;
    line-height: 1;
}
.slid_counter span{
    color: #b1b3b9;
    font-weight: 100;
    font-size: 18px;
    line-height: 1;
}
/* galery */


/* get_flat */
.get_flat{
    position: relative;
    padding-top: 80px;
}
.get_flat .tm{
    position: relative;
    z-index: 2;
}
.gf_filter {
    display: flex;
    align-content: center;
    justify-content: space-between;
    position: relative;
    margin-top: 150px;
    max-width: 1090px;
    z-index: 2;
    align-items: center;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 0px 44px;
}
.get_dec{
    display: block;
    position: absolute;
    height: 380px;
    width: calc(50vw - 686px + 1362px);
    background: url(../images/get_dec.jpg)no-repeat top right;
    /* background-size: 100%; */
    bottom: 0;
    left: auto;
    right: 0;
    z-index: 1;
}



.get_nav{
    display: block;
    position: relative;
    box-sizing: border-box;
}
.get_nav a{
    display: inline-block;
    vertical-align: top;
    position: relative;
    cursor: pointer;
    transition: 0.4s;
}
.get_nav a:after {
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 5px;
    background: #58c6e8;
    left: 0;
    bottom: 1px;
    opacity: 0;
    transition: 0.4s;
}
.get_nav a + a{
    margin-left: 16px;
}
.get_nav a span{
    display: block;
    position: relative;
    font-size: 18px;
    line-height: 1;
    z-index: 2;
    text-decoration: underline;
    color: #3b4251;
    transition: 0.4s;
}
.get_nav a:hover span{
    color: #5ac4ee;
    text-decoration: none;
}
.get_nav a.act span{
    text-decoration: none;
}
.get_nav a.act:after{
    opacity: 1;
}
.get_nav a.act:hover span{
    color: #3b4251;
}





.gf_floor_slid{
    display: block;
}
.gf_floor_slid .gf_txt{
    display: inline-block;
    vertical-align: top;
    font-size: 18px;
    line-height: 1;
    margin-right: 47px;
    color: #6b747c;
}
.gf_floor_slid .slid_style_react.MuiSlider-root{
    display: inline-block;
    vertical-align: top;
    width: 410px;
}
.slid_style_react.MuiSlider-root {
    padding: 0;
    margin-top: 15px;
    height: 1px;
    color: #D3D3D3;
}
.slid_style_react .MuiSlider-rail{
    height: 1px;
    background: #d9e0e4;
    opacity: 1;
}
.slid_style_react .MuiSlider-valueLabel{
    font-size: 22px;
    top: -26px;
    left: auto;
}
.slid_style_react .MuiSlider-valueLabel > span{
    width: 32px;
    height: auto;
    line-height: 100%;
    transform: none;
    border-radius: 0px;
    background-color: initial;
}
.slid_style_react .MuiSlider-valueLabel > span > span{
    transform: none;
    color: #3b4251;
    font-family: 'robotoslab';
}
.slid_style_react .MuiSlider-thumb.MuiSlider-active{
    box-shadow: none;
}
.slid_style_react .MuiSlider-thumb.Mui-focusVisible, .slid_style_react .MuiSlider-thumb:hover{
    box-shadow: none;
}
.slid_style_react .MuiSlider-thumb{
    width: 18px;
    height: 18px;
    background: #56cbf0;
    box-sizing: border-box;
    /* border: 2px solid #e2001a; */
    margin-top: -9px;
}
.slid_style_react .MuiSlider-track {
    height: 1px;
    color: #56cbf0;
}




.gf_filter .form_main {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 70px;
}
.gf_filter .form_main .react_input_style{
    width: 30%;
    width: 48%;
}
.MuiFormControl-root{
    width: 100%;
}
.MuiFormLabel-root{
    color: #a9a8a8;
    font-size: 16px;
}
.react_input_style .MuiInputLabel-root {
    color: #6b747c;
    top: 8px;
    font-family: 'robotoslab'!important;
    font-size: 18px;
}
.react_input_style .MuiFormLabel-root.Mui-focused{
    color: #a9a8a8;
}

.react_input_style.react_textarea .MuiInputBase-input{
    height: 56px!important;
    width: 100%!important;
    line-height: 56px;
}
.MuiInputBase-multiline{padding: 0!important;}
.react_input_style .MuiInputBase-input{
    color: #a9a8a8;
    padding: 0;
    padding-left: 38px;
    font-size: 18px;
    height: 56px;
    font-family: 'robotoslab'!important;
}
.MuiInput-underline:after{
    display: none;
}
.MuiInput-underline:hover:not(.Mui-disabled):before{
    border-bottom: 1px solid  #e6e6e6!important;    
}
.MuiInput-underline:before{
    border-bottom: 1px solid  #e6e6e6!important;
}
.MuiFormLabel-filled ~ .MuiInput-underline:before{    
    border-bottom: 1px solid  #e6e6e6;    
}
.MuiInputLabel-formControl{
    left: 34px!important;
    line-height: 0!important;
}
label + .MuiInput-formControl{
    margin-top: 0!important;
}

.input_name {
    background: url(../images/input_name.svg)no-repeat bottom 12px left;
    background-size: 25px;
}
.input_mail {
    background: url(../images/input_mail.svg)no-repeat bottom 12px left;
    background-size: 25px;
}
.input_phone {
    background: url(../images/input_phone.svg)no-repeat bottom 12px left;
    background-size: 25px;
}
.input_text {
    background: url(../images/input_text.svg)no-repeat bottom 12px left;
    background-size: 23px;
}
.input_time {
    background: url(../images/cont_time.svg)no-repeat center left;
    background-size: 25px;
}
.input_vznos {
    background: url(../images/input_vznos.svg)no-repeat center left;
    background-size: 25px;
}


.btn_style{
    display: block;
    position: relative;
    margin:  0 auto;
    height: 46px;
    border:none;
    border: 1px solid #ffc140;
    text-decoration: none;
    background: #ffc140;
    text-align: center;
    font-size: 18px;
    line-height: 46px;
    color: #3b4251;
    font-family: 'robotoslab'!important;
    cursor: pointer;
    transition: 0.4s;
}
.btn_style:hover{
    background: #fff;
}
.get_flat .btn_style{
    margin-top: 46px;
    transform: translateY(50%);
    width: 300px;
}
/* get_flat */

/* how_buy */
.how_buy{
    display: block;
    padding-top: 180px;
    padding-bottom: 140px;
    background: url(../images/hb_bg.jpg)no-repeat center left 34%;
}
.how_buy .tm{
    display: block;
    vertical-align: top;
}
.how_buy .cw_main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.hb_list{
    display: block;
    font-size: 0;
}
.hb_in{
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
}
.hb_in + .hb_in{
    margin-left: 130px;
}
.hb_in img{
    display: block;
    margin: 0 auto;
    margin-left: 0;
    width: 58px;
    margin-bottom: 26px;
}
.hb_in span{
    display: block;
    position: relative;
    font-size: 24px;
    line-height: 1;
    color: #3b4251;
    margin-bottom: 22px;
}
.hb_in span l{
    display: block;
    position: relative;
    z-index: 2;
}
.hb_in span:after {
    display: block;
    position: absolute;
    content: '';
    width: 0;
    height: 8px;
    background: #58c6e8;
    left: 0;
    bottom: 1px;
    opacity: 0;
    transition: 0.4s;
}
.hb_in:hover span:after{
    opacity: 1;
    width: 68px;
}
.hb_in a{
    color: #c3c7ce;
    text-decoration: underline #c3c7ce;
    transition: 0.4s;
    font-size: 17px;
    line-height: 1;
}
.hb_in:hover a{
    text-decoration: underline #58c6e8;
    color: #58c6e8;
}
/* how_buy */

/* hod_str */
.hod_str{
    padding-top: 60px;
    padding-bottom: 130px;
}
.hod_nav {
    display: flex;
    align-content: flex-end;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 60px;
}
.year_list {
    display: flex;
    align-content: flex-end;
    justify-content: flex-start;
    align-items: flex-end;
    min-height: 24px;
}
.year_list a{
    display: inline-block;
    vertical-align: top;
    position: relative;
    font-size: 20px;
    line-height: 1;
    color: #b8b9bd;
    min-width: 70px;
    font-family: 'robotoslab';
    text-align: center;
    cursor: pointer;
    transition: .3s;
}
.year_list a span{
    display: block;
    position: relative;
    z-index: 2;
}
.year_list a:hover{
    color: #3c3f47;
}

.year_list a:after {
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 8px;
    background: #58c6e8;
    left: 0;
    bottom: 1px;
    opacity: 0;
    transition: 0.4s;
}


.year_list a+a{
    margin-left: 5px;
}
.year_list a.act{
    font-size: 30px;
    color: #3c3f47;
}
.year_list a.act:after {
    opacity: 1;
}


.month_list{
    display: block;
    position: relative;
}
.month_list a{
    display: inline-block;
    position: relative;
    vertical-align: top;
    font-size: 18px;
    padding: 0 4px;
    cursor: pointer;
}
.month_list a+a{
    margin-left: 24px;
}
.month_list a span{
    position: relative;
    z-index: 4;
}


.month_list a span:after{
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background: #384251;
    left: 0;
    bottom: 2px;
    opacity: 1;
    transition: 0.3s;
    pointer-events: none;
}
.month_list a:hover span:after{
    opacity: 0;
    bottom: -5px;
}
  
.month_list a:after {
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 8px;
    left: 0;
    bottom: -3px;
    background: #58c6e8;
    opacity: 0;
    transition: 0.3s;
    pointer-events: none;
}

.month_list a:hover:after {
    opacity: 1;
    bottom: 0px;
}
.month_list a.act:after{
    opacity: 0;
}
.month_list a.act span:after{
    opacity: 0;
}
.month_list a.act{
    color: #5ac4ee;
}
.hod_slid{
    width: 100%;
    margin-top: 38px;
}
.hod_in {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding-right: 5px;
}
.hod_in img{
    display: block;
    width: 100%;
}
.hod_str .slid_nav{
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 40px;
}
/* hod_str */



/* about_us */
.about_us{
    position: relative;
    padding-bottom: 170px;
    margin-top: 70px;
}
.about_us .cw_main{
    position: relative;
}
.about_us .cw_main p {
    max-width: 654px;
    margin-top: 58px ;
    font-size: 20px;
    color: #3b4454;
    line-height: 1.2;
}
.about_us .cw_main p + p{
    margin-top: 24px;
}
.about_us .cw_main p span{
    text-transform: uppercase;
    font-size: 18px;
    line-height: 1.1;
    color: #58c6e8;
}
.about_us .cw_main p a{
    color: #58c6e8;
    text-decoration: underline #58c6e8;
}
.about_us .cw_main p a:hover{
    text-decoration: none;
}
.doc_btn {
    display: inline-block;
    position: relative;
    margin-top: 100px;
    margin-left: 130px;
    text-transform: uppercase;
    font-size: 17px;
    line-height: 1;
    color: #3b4251;
    text-decoration: none;
}
.doc_btn span{
    display: block;
    position: relative;
    z-index: 2;
}
.doc_btn:before{
    display: block;
    position: absolute;
    content: '';
    width: 123px;
    height: 131px;
    background: url(../images/doc_btn_icon.png)no-repeat center center;
    background-size: 100%;
    left: -130px;
    bottom: -46px;
    pointer-events: none;
}
.doc_btn:after{
    display: block;
    position: absolute;
    content: '';
    width: 84px;
    height: 8px;
    background: #56cbf0;
    left: 0;
    bottom: -3px;
    transition: 0.4s;
}
.doc_btn:hover:after{
    width: 100%;
}
.au_img{
    display: block;
    position: absolute;
    right: 0;
    top: 0;
}
/* about_us */


/* contacts */
.contacts{
    display: block;
    position: relative;
    background: url(../images/cont_bg.jpg)no-repeat center top;
    background-size: cover;
    padding-top: 104px;
    padding-bottom: 100px;
}
.contacts .cw_main {
    display: flex;
    align-content: flex-start;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
}
.cont_info{
    display: block;
    max-width: 380px;
}
.cont_addr span, .cont_phone span, .cont_time span{
    display: block;
    position: relative;
    padding-left: 34px;
    font-size: 18px;
    line-height: 1.1;
    color: #b9c1c8;
}
.cont_addr span:before, .cont_phone span:before, .cont_time span:before{
    display: block;
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}
.cont_addr span:before{
    background: url(../images/cont_addr.svg)no-repeat center top;
    background-size: 100%;
    width: 27px;
    height: 30px;
}
.cont_phone span:before{
    background: url(../images/cont_phone.svg)no-repeat center top;
    background-size: 100%;
    width: 25px;
    height: 25px;
}
.cont_time span:before{
    background: url(../images/cont_time.svg)no-repeat center top;
    background-size: 100%;
    width: 25px;
    height: 28px;
}
.cont_addr{
    display: block;
    margin-top: 62px;
}
.cont_addr p, .cont_time p{
    display: block;
    line-height: 1;
    font-size: 14px;
    margin-top: 14px;
}
.cont_phone {
    margin-top: 44px;
}
.cont_phone a{
    display: inline-block;
    vertical-align: top;
    color: #505562;
    font-size: 20px;
    line-height: 1;
    text-decoration: none;
    margin-top: 14px;
}
.cont_time {
    margin-top: 44px;
}
.quest{
    display: block;
    margin-top: 50px;
}
h6{
    display: block;
    position: relative;
    font-size: 30px;
    line-height: 1;
    color: #505562;
    font-weight: normal;
}
h6 span{
    position: relative;
    z-index: 2;
}
h6:after{
    display: block;
    position: absolute;
    content: '';
    width: 84px;
    height: 8px;
    background: #56cbf0;
    left: 0;
    bottom: 0px;
    transition: 0.4s;
}
.quest .btn_style{
    font-size: 18px;
    margin-top: 26px;
}
.cont_map{
    display: block;
    position: relative;
    width: 632px;
    height: 500px;
}
#map{
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
}
/* .ymaps-2-1-79-events-pane, .ymaps-2-1-79-ground-pane, .ymaps-2-1-79-copyrights-pane, .ymaps-2-1-79-controls-pane {
	filter: grayscale(1)!important;
	-ms-filter: grayscale(1)!important;
	-webkit-filter: grayscale(1)!important;
	-moz-filter: grayscale(1)!important;
	-o-filter: grayscale(1)!important;
} */
/* contacts */
.footer{
    display: block;
    background: #505562;
    padding-top: 18px;
    padding-bottom: 18px;
}
.footer .cw_main {
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}
.f_right, .f_center, .f_left{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}
.f_left img {
    display: block;
    width: 70px;
}
.footer .cw_main span{
    margin: 0 13px;
    font-size: 14px;
    line-height: 1;
    color: #fff;
    opacity: .45;
}
.footer .cw_main a{
    color: #56cbf0;
    font-size: 14px;
    line-height: 1;
    text-decoration: underline #56cbf0;
}
.footer .cw_main a:hover{
    text-decoration: none;
}



/*popup_rgba*/

.popup_rgba {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99999999;
    /* background: url("../img/popup_rgba.png"); */
    background: rgba(6, 31, 35, .7);
    overflow: hidden;
    -ms-overflow-y: scroll;
        overflow-y: scroll;
}

    .popup_cell {
        display: table-cell;
        vertical-align: middle;
    }
    .popup_table {
        position: absolute;
        display: table;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
    .closeform {
    display: block;
    position: absolute;
    background: url(../images/closeform.png)no-repeat center center;
    -webkit-background-size: cover;
    background-size: cover;
    width: 30px;
    height: 30px;
    top: 20px;
    right: 20px;
    z-index: 999999;
}
    .closeform:hover{opacity:  0.5;}









   .popup_main {
        position: relative;
        margin: 0 auto;
        margin-top: 50px;
        max-width: 630px;
        margin-bottom: 70px;
        background: #FFF;
        box-sizing: border-box;
        padding: 146px 100px 90px 100px;
        text-align: center;
    }
    .pu_tm{
        display: inline-block;
        position: relative;
        width: 100%;
    }
    .pu_tm span{
        display: inline-block;
        position: relative;
        font-weight: normal;
        font-size: 36px;
        text-transform: uppercase;
        line-height: 1;
        z-index: 2;
    }
    .pu_tm span:before{
        display: block;
        position: absolute;
        content: '';
        width: 57px;
        height: 3px;
        background: #58c6e8;
        left: 50%;
        transform: translateX(-50%);
        top: -28px;
    }
    .pu_tm sup{
        display: block;
        color: #56caf0;
        font-size: 24px;
        line-height: 1;
        margin-top: 8px;
    }
    .pu_form{
        display: block;
        width: 100%;
        margin-top: 90px;
    }
    .pu_form .react_input_style+.react_input_style{
        margin-top: 32px;
    }
    .pu_form .btn_style{
        width: 100%;
        margin-top: 42px;
        height: 54px;
        line-height: 54px;
    }
    .pu_thx .pu_tm span:before{
        left: -80px;
        transform: none;
        top: 50%;
        transform: translateY(-50%);
    }
    .pu_tm p{
        display: block;
        margin-top: 20px;
        font-size: 22px;
        line-height: 1;
        color: #56caf0;
    }










    
.pu_form .react_input_style .MuiInputBase-input{
    padding-left: 42px;
}
.pu_form .MuiInputLabel-formControl{
    left: 42px!important;
}
.pu_form .react_input_style .MuiInputLabel-root{
    font-size: 22px;
}

.react_select_style{
    /* margin-top: 16px; */
    margin-bottom: 32px;
    width: 100%;
}
.react_select_style.MuiInput-underline:after{
    display: none;
}
.react_select_style.MuiInput-underline:before{
    border-bottom: 1px solid  #f3f3f3;
}
.react_select_style.MuiInput-underline:hover:not(.Mui-disabled):before{
    border-bottom: 1px solid  #f3f3f3;    
}
.react_select_style .MuiNativeSelect-select {
    background: none;
    padding: 0;
    padding-right: 24px;
    padding-left: 42px;
    height: 56px;
}
.react_select_style.MuiInputBase-root {
    font-family: 'robotoslab'!important;
    color: #6b747c;
    font-size: 22px;
}
.react_select_style .MuiNativeSelect-select:not([multiple]) option, .react_select_style .MuiNativeSelect-select:not([multiple]) optgroup{
    color: #000;
}
.react_select_style .MuiNativeSelect-select:focus{
    background: none;
}
.react_select_style .MuiNativeSelect-icon path{
    display: none;
}
.react_select_style .MuiNativeSelect-icon{
    background: url(../images/select_ar.svg)no-repeat center center;
    background-size: 14px 8px;
    opacity: 1;
    transform: scale(1,1);
    transition: .2s;
}
.react_select_style .MuiNativeSelect-select:focus ~ .MuiNativeSelect-icon{
    opacity: .7;
    transform: scale(-1,-1);
    transition: .2s;
}

.pu_flat{
    max-width: 1232px;
    padding: 110px 100px 106px 150px;
    text-align: left;
}
.pu_flat .pu_tm span:before{
    left: -80px;
    transform: none;
    top: 50%;
    transform: translateY(-50%);
}
.area {
    display: block;
    margin-top: 18px;
}
.area span{
    font-size: 18px;
    line-height: 1;
    color: #afb4b7;
}
.area span b{
    font-weight: normal;
    color: #3b4251;   
    padding-left: 10px;
}
.area span b sup{
    font-size: 8px;
    line-height: 0;
}
.area span + span{
    margin-left: 54px;
}
.flat_img {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    float: left;
    margin-top: 40px;
    width: 424px;
    height: 412px;
}
.flat_img img{
    display: block;
    max-width: 100%;
    max-height: 100%;
}
.form_tit{
    display: block;
    text-transform: uppercase;
    font-size: 26px;
    line-height: 1.1;
    color: #56caf0;
    margin-bottom: 42px;
}

.pu_flat .pu_form{
    display: block;
    max-width: 430px;
    float: right;
    margin-top: 0;
    padding-top: 110px;
}
.pu_doc{
    max-width: 970px;
    padding: 120px 40px 74px 74px;
}
.doc_list{
    display: block;
    margin-top: 94px;
    font-size: 0;
}
.doc_in{
    display: inline-block;
    vertical-align: top;
    width: 50%;
    box-sizing: border-box;
    padding-right: 40px;
    text-align: left;
}
.doc_in a{
    display: block;
    font-size: 17px;
    line-height: 1;
    color: #57caf0;
    text-decoration: underline;
}
.doc_in a + a{
    margin-top: 14px;
}
.doc_in a:hover{
    text-decoration: none;
}

.pu_action {
    position: fixed;
    width: 570px;
    left: 0;
    top: 50%;
    transform: translate(-100%, -50%);
    z-index: 500;
    background: #fff;
    box-shadow: 0px 0px 20px rgba(0,0,0,.2);
    transition: 1s;
}

.pu_action__open {
    transform: translate(0, -50%);
}

.pu_action .pu_form {
    display: block;
    width: auto;
    min-width: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
}

.pu_action__img {
    display: block;
    width: 100%;
}

.pu_action__btn {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(100%);
}

    .pu_action__btn img {
        display: block;
    }