
/*@media*/
@media screen and (max-height:  860px) {
    .menu .cw_main{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    logo {
        max-width: 80px;
    }
    .popup_main{
        padding: 90px;
    }
    .pu_form{
        margin-top: 50px;
    }
}
@media screen and (max-height:  780px) {
    .pu_action {
        width: 440px;
    }
    
    .pu_action .pu_form .react_input_style .MuiInputLabel-root {
        font-size: 16px;
    }

    .pu_action .react_input_style .MuiInputBase-input {
        height: 46px;
    }

    .pu_action .pu_form .MuiInputLabel-formControl {
        top: -4px;
    }
    .pu_action .pu_form .react_input_style+.react_input_style {
        margin-top: 19px;
    }
    .pu_action .pu_form .btn_style {
        margin-top: 18px;
    }
    .pu_action .pu_form {
        margin-top: 29px;
    }
}
@media screen and (max-width: 1600px){    
    .get_dec{
        background: url(../images/get_dec.jpg)no-repeat top right -170px;
    }
}
@media screen and (max-width: 1550px){    
    .tm{
        padding-left: 82px;
    }
    .tm:before{
        left: 0;
    }
    .presentation .tm{
        padding-left: 0;
    }
    .presentation .tm:before{
        display: none;
    }
    .nh_in {
        height: 500px;
        max-width: 620px;
    }
    .nh_in img {
        max-height: 100%;
        max-width: 100%;
    }
}
@media screen and (max-width: 1500px) {
    .cw_main, .nh_slid_wrap {
        padding: 0 60px;
    }
}
@media screen and (max-width: 1410px) {
    .get_dec{
        background: url(../images/get_dec.jpg)no-repeat top right -300px;
    }
    .comf_list {
        margin-left: 82px;
        width: calc(100% - 82px);
    }
    .cmf_el{
        width: 30%;
    }
    .cmf_el:nth-child(2){
        width: 32%;
    }
.cmf_el:nth-child(2) .cmf_img img{
    width: 100%;
}
.cmf_el:nth-child(2) .cmf_img{
    width: 100%;
}
.nh_slid_wrap{
    padding: 0 10px;
}
.gl_in img{
    display: block;
    width: 100%;
}
.cw_main, .nh_slid_wrap {
    padding: 0 60px;
}
}
@media screen and (max-width: 1360px) {
    .loc_el + .loc_el {
        margin-left: 10%;
    }
    .cw_main{
        padding: 0 10px;
    }
    .prst_dec {
        width: calc(50vw - 686px + 1262px);
        background: url(../images/prst_dec.jpg)no-repeat right -362px bottom;
    }
    .hb_in + .hb_in {
        margin-left: 50px;
    }
    .hb_in span{
        font-size: 20px;
    }
    .about_us .cw_main p{
        font-size: 14px;
    }
    .about_us .cw_main p span{
        font-size: 16px;
    }
    .au_img {
        max-width: 50%;
    }
    .about_us .cw_main p {
        max-width: 534px;
    }

    .cw_main {
        padding: 0 60px;
    }
}
@media screen and (max-width: 1250px){
    .nh_in {
        height: 410px;
        max-width: 510px;
    }
    .nh_nav{
        padding-top: 76px;
    }
    .pu_flat{
        width: 98%;
    }
}
@media screen and (max-width: 1150px){
    .header {
        min-height: 500px;
    }
    .cw_main, .nh_slid_wrap {
        padding: 0 10px;
    }
    .tm span {
        font-size: 26px;
    }
    .footer .cw_main{
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
    }
    .f_center{
        margin-top: 12px;
        margin-bottom: 12px;
    }
    .contacts{
        padding-top: 50px;
        padding-bottom: 0px;
    }
    .contacts .cw_main{
        flex-direction: column;
    }
    .cont_info{
        margin: 0 auto;
        margin-bottom: 20px;
    }
    .cont_map{
        width: 100%;
        height: 300px;
    }
    .au_img{
        display: block;
        position: relative;
        margin: 0 auto;
        margin-top: 20px;
        max-width: 300px;
    }
    .about_us{
        padding-bottom: 90px;
    }
    .about_us .cw_main{
        text-align: center;
    }
    .about_us .cw_main p {
        max-width: 300px;
        margin: 0 auto;
        margin-top: 30px;
        text-align: left;
    }
    .doc_btn {
        margin-left: 0px;
        font-size: 14px;
    }
    .doc_btn:before{
        left: -90px;
    }
    .hod_str{
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .hod_nav{
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
        max-width: 300px;
        margin: 0 auto;
        margin-top: 30px;
        text-align: center;
    }
    .month_list{
        margin-top: 20px;
    }
    .hod_slid{
        width: 300px;
        margin: 0 auto;
        margin-top: 30px;
    }
    .how_buy .cw_main{
        flex-direction: column;
    }
    .how_buy{
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .hb_list{
        max-width: 300px;
        margin: 0 auto;
        margin-top: 40px;
    }
    .hb_in{
        display: block;
        width: 100%;
    }
    .hb_in+.hb_in{
        margin: 0 auto;
        margin-top: 30px;
    }
    .get_flat{
        padding-top: 50px;
        padding-bottom: 0;
        background: #f4fcfe;
    }
    .get_dec{
        display: none;
    }
    .gf_filter{
        margin: 0 auto;
        flex-direction: column;
        margin-top: 60px;
        max-width: 300px;
        padding: 0;
    }
    .get_nav a span{
        /* font-size: 14px; */
    }
    
    .gf_floor_slid{
        width: 100%;
        margin-top: 40px;
    }
    .gf_filter .form_main{
        margin-top: 30px;
    }
    .gf_filter .form_main{
        flex-direction: column;
    }
    .gf_filter .form_main .react_input_style{
        width: 100%;
        margin-bottom: 18px;
    }
    .get_flat .btn_style{
        margin-top: 0;
    }
    .gf_floor_slid .gf_txt{
        display: block;
        margin: 0 auto;
        text-align: center;
    }
    .gf_floor_slid .slid_style_react.MuiSlider-root{
        width: 100%;
        margin-top: 60px;
    }
    .galery .slid_nav {
        position: relative;
        width: 300px;
        padding: 20px;
        margin: 0 auto;
    }
    .galery{
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .plpr_nav{
        max-width: 300px;
        margin: 0 auto;
        margin-top: 40px;
        text-align: center;
    }
    .plan_price{
        padding-bottom: 50px;
    }
    .plpr_list{
        max-width: 300px;
        margin: 0 auto;
        flex-direction: column;
        margin-top: 40px;
    }
    .plpr_in{
        width: 100%;
    }
    .plpr_name{
        font-size: 16px;
    }
    .plpr_nav p{
        text-align: center;
    }
    .plpr_nav a span{
        font-size: 18px;
    }
    .plpr_nav a + a {
        margin-left: 18px;
    }
    .plpr_in{
        margin-bottom: 40px;
    }
    .tm sup{
        font-size: 14px;
    }
    .loc{
        padding-top: 50px;
    }
    .near_house{
        padding-top: 100px;
        padding-bottom: 50px;
    }
    .nh_slid_wrap{
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
    }
    .nh_nav{
        padding: 0;
        width: 317px;
        max-width: 100%;
    }
    .nh_nav li span{
        font-size: 11px;
    }
    .nh_nav li+li {
        margin-top: 6px;
    }
    .nh_slid{
        width: 300px;
        margin-top: 30px;
    }
    .nh_in{
        /* height: 410px; */
        height: auto;
        width: auto;
    }
    .nh_in img{
        width: 100%;
    }
    .near_house .slid_nav {
        position: relative;
        width: 300px;
        padding: 20px;
        border: 1px solid lightgray;
        right: auto;
        font-size: 14px;
    }
    .presentation{
        padding-top: 50px;
        padding-bottom: 0;
        background: #f4fcfe;
    }
    .presentation .cw_main{
        padding: 0;
    }
    .presentation .cw_main .btn_style{
        display: block;
        margin: 0 auto;
        width: 300px;
        padding: 0;
    }
    .prst_dec{
        display: none;
    }
    .loc_list{
        max-width: 300px;
        margin: 0 auto;
        margin-top: 50px;
        padding: 0;
    }
    .loc_el{
        display: block;
    }
    .loc_el:nth-child(odd){
        margin-top: 18px;
    }
    .loc_el + .loc_el{
        margin-left: 0;
        margin-top: 18px;
    }
    .loc_el:before{
        display: none;
    }
    .loc_list:after{
        display: none;
    }
    .loc_info span{
        font-size: 17px;
    }
    .menu_btn {
        display: flex;
        width: 50px;
        height: 50px;
        background: #58c6e8;
        align-content: center;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .menu_btn span{        
        display: block;
        color: #fff;
        font-size: 12px;
        line-height: 1;
    }
    .top_menu{
        display: none;
    }
    .popup_menu{
        background: none;
        padding: 0 !important;
    }
    .menu_list{
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        flex-direction: column;
    }
    .menu_list a{
        display: block;
        text-decoration: none;
        text-transform: uppercase;
        color: #fff;
        margin: 10px 0px;
    }
    h1{
        font-size: 44px;
    }
    .hd_loc {
        font-size: 14px;
        background-size: 22px;
    }
    .hd_info{
        font-size: 14px;
    }
    .hd_inner:before {
        box-shadow: 0px 0px 49px 110px rgb(255 255 255 / 80%);
    }
    

    .comfort{
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .comf_list{
        margin: 0 auto;
        margin-top: 40px;
        flex-direction: column;
        width: 300px;
    }
    .cmf_el{
        width: 100%!important;
    }
    .cmf_el:nth-child(2){
        margin-top: 44px;
        margin-bottom: 44px;
    }
    .tm{
        padding: 0;
        text-align: center;
    }
    .tm:before{
        display: none;
    }
    .tm {
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    .gl_slid {
        margin-top: 0;
    }
    .slid_nav {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .plan_price {
        padding-bottom: 30px;
    }
    .comfort {
        padding-top: 30px;
    }

    .galery {
        padding-top: 80px;
    }

    .galery .tm {
        padding-bottom: 30px;
    }
}
@media screen and (max-width: 1080px){
    .pu_flat {
        width: 300px;
        padding: 90px 20px 50px;
    }
    .pu_flat .pu_tm span:before{
        display: none;
    }
    .area span{display: block;}
    .area span + span{
        margin: 0 auto;
        margin-top: 12px;
    }
    .flat_img {
        float: none;
        margin-top: 20px;
        width: 100%;
        height: 320px;
    }
    .pu_flat .pu_form {
        max-width: 100%;
        float: none;
        padding-top: 30px;
    }
    .form_tit{
        font-size: 16px;
        margin-bottom: 20px;
    }
    .pu_tm span{
        font-size: 26px;
    }
    .pu_tm sup{
        font-size: 16px;
    }
}
@media screen and (max-width: 1000px){
    .pu_doc {
        max-width: 98%;
    }
}
@media screen and (max-width: 780px) {
    .doc_list{
        margin-top: 30px;
    }
    .doc_in{
        width: 100%;
        display: block;
    }
    .doc_in+.doc_in{
        margin-top: 12px;
    }
}
@media screen and (max-width: 730px){
    logo {
        max-width: 50px;
    }
    .call_phone {
        font-size: 10px;
        padding: 7px 10px 6px 32px;
        background-size: 14px;
    }
    .call_phone:hover{
        background-size: 14px;
    }

}
@media screen and (max-width: 650px){
    .popup_main {
        padding: 90px 20px 50px;
        width: 98%;
    }
    .pu_tm span{
        font-size: 26px;
    }
    .pu_tm sup{
        font-size: 14px;
    }
}
@media screen and (max-width: 500px){
    .pu_action__btn img {
        width: 40px;
    }
    .pu_action {
        width: calc(100% - 50px);
    }
    .pu_form .btn_style {
        font-size: 16px;
        height: 44px;
        line-height: 44px;
    }
}
@media screen and (max-width: 480px){ 
    h1 {
        font-size: 34px;
    }
    .about_us {
        padding-bottom: 30px;
    }
    .contacts {
        padding-top: 30px;
    }
    .cont_addr {
        margin-top: 32px;
    }
    .galery {
        padding-top: 68px;
    }
    .near_house {
        padding-top: 30px;
    }
    .how_buy {
        padding-bottom: 30px;
    }
    .comfort {
        padding-bottom: 0;
    }
    
}
/*@media*/